var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hasAccess
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  '\n      You do not have "Reporting - KPI Widgets permissions"\n    '
                )
              ])
            ],
            1
          )
        : _vm.dates.length === 2 && !_vm.resultsLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pb-0" },
                [
                  _vm.results.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pr-0", attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-progress-circular",
                                {
                                  attrs: {
                                    rotate: -90,
                                    size: 100,
                                    width: 15,
                                    value: _vm.results[0].rate,
                                    color: "#98EB108A"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "52", color: "black" } },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.results[0].icon || _vm.icon
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    [
                                      _c("v-list-item-title"),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "text-wrap",
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center",
                                              "font-weight": "bold"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.dateRangeText(_vm.dates)
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center",
                                              "font-weight": "bold"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                VS\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          staticClass: "text-wrap",
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center",
                                              "font-weight": "bold"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.dateRangeText(
                                                  _vm.comparisonDates
                                                )
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "left"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.results[0].name) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                (" +
                                              _vm._s(_vm.results[0].rate) +
                                              "%)\n              "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          _vm.getDifference(
                                            _vm.results[0].rate,
                                            _vm.resultsCompare[0].rate
                                          ) > 0
                                            ? _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "medium",
                                                    color: "green"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  mdi-arrow-up-bold-circle-outline\n                "
                                                  )
                                                ]
                                              )
                                            : _vm.getDifference(
                                                _vm.results[0].rate,
                                                _vm.resultsCompare[0].rate
                                              ) == 0
                                            ? _c("div")
                                            : _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "medium",
                                                    color: "error"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  mdi-arrow-down-bold-circle-outline\n                "
                                                  )
                                                ]
                                              ),
                                          Array.isArray(_vm.resultsCompare) &&
                                          _vm.resultsCompare.length
                                            ? _c(
                                                "span",
                                                {
                                                  style:
                                                    "color: " +
                                                    _vm.getFontColor(
                                                      _vm.results[0].rate,
                                                      _vm.resultsCompare[0].rate
                                                    )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        " " +
                                                          _vm.getAbsDifference(
                                                            _vm.results[0].rate,
                                                            _vm
                                                              .resultsCompare[0]
                                                              .rate
                                                          )
                                                      ) +
                                                      "%"
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-list-item-title",
                                        {
                                          style: [
                                            {
                                              display: "flex",
                                              "justify-content": "center"
                                            }
                                          ]
                                        },
                                        [
                                          _vm._v(
                                            "\n                (" +
                                              _vm._s(
                                                _vm.resultsCompare[0].rate
                                              ) +
                                              "%)\n              "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm.iconTwo &&
                                  _vm.infoDescriptionTwo &&
                                  _vm.results.length > 1
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                { "justify-content": "left" }
                                              ]
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "z-index": "15"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                    size: "24"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .results[1]
                                                                        .icon ||
                                                                        _vm.iconTwo
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1531900612
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.infoDescriptionTwo
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.results[1].name) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                {
                                                  display: "flex",
                                                  "justify-content": "center"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                (" +
                                                  _vm._s(_vm.results[1].rate) +
                                                  "%)\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                {
                                                  display: "flex",
                                                  "justify-content": "center"
                                                }
                                              ]
                                            },
                                            [
                                              _vm.getDifference(
                                                _vm.results[1].rate,
                                                _vm.resultsCompare[1].rate
                                              ) > 0
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pb-1",
                                                      attrs: {
                                                        size: "medium",
                                                        color: "green"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  mdi-arrow-up-bold-circle-outline\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm.getDifference(
                                                    _vm.results[1].rate,
                                                    _vm.resultsCompare[1].rate
                                                  ) == 0
                                                ? _c("div")
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        size: "medium",
                                                        color: "error"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  mdi-arrow-down-bold-circle-outline\n                "
                                                      )
                                                    ]
                                                  ),
                                              Array.isArray(
                                                _vm.resultsCompare
                                              ) && _vm.resultsCompare.length
                                                ? _c(
                                                    "span",
                                                    {
                                                      style:
                                                        "color: " +
                                                        _vm.getFontColor(
                                                          _vm.results[1].rate,
                                                          _vm.resultsCompare[1]
                                                            .rate
                                                        )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            " " +
                                                              _vm.getAbsDifference(
                                                                _vm.results[1]
                                                                  .rate,
                                                                _vm
                                                                  .resultsCompare[1]
                                                                  .rate
                                                              )
                                                          ) +
                                                          "%"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                {
                                                  display: "flex",
                                                  "justify-content": "center"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                (" +
                                                  _vm._s(
                                                    _vm.resultsCompare[1].rate
                                                  ) +
                                                  "%)\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.iconThree &&
                                  _vm.infoDescriptionTwo &&
                                  _vm.results.length > 1
                                    ? _c(
                                        "v-list-item",
                                        [
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                { "justify-content": "left" }
                                              ]
                                            },
                                            [
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    top: "",
                                                    "z-index": "15"
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function(ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                    size: "24"
                                                                  }
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "\n                      " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .results[2]
                                                                        .icon ||
                                                                        _vm.iconThree
                                                                    ) +
                                                                    "\n                    "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1530192645
                                                  )
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.infoDescriptionThree
                                                      )
                                                    )
                                                  ])
                                                ]
                                              ),
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(_vm.results[2].name) +
                                                  "\n              "
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                {
                                                  display: "flex",
                                                  "justify-content": "center"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                (" +
                                                  _vm._s(_vm.results[2].rate) +
                                                  "%)\n              "
                                              )
                                            ]
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                {
                                                  display: "flex",
                                                  "justify-content": "center"
                                                }
                                              ]
                                            },
                                            [
                                              _vm.getDifference(
                                                _vm.results[2].rate,
                                                _vm.resultsCompare[2].rate
                                              ) > 0
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pb-1",
                                                      attrs: {
                                                        size: "medium",
                                                        color: "#4CAF50"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  mdi-arrow-up-bold-circle-outline\n                "
                                                      )
                                                    ]
                                                  )
                                                : _vm.getDifference(
                                                    _vm.results[2].rate,
                                                    _vm.resultsCompare[2].rate
                                                  ) == 0
                                                ? _c("div")
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "pb-1",
                                                      attrs: {
                                                        size: "medium",
                                                        color: "#F44336"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  mdi-arrow-down-bold-circle-outline\n                "
                                                      )
                                                    ]
                                                  ),
                                              Array.isArray(
                                                _vm.resultsCompare
                                              ) && _vm.resultsCompare.length
                                                ? _c(
                                                    "span",
                                                    {
                                                      style:
                                                        "color: " +
                                                        _vm.getFontColor(
                                                          _vm.results[2].rate,
                                                          _vm.resultsCompare[2]
                                                            .rate
                                                        )
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            " " +
                                                              _vm.getAbsDifference(
                                                                _vm.results[2]
                                                                  .rate,
                                                                _vm
                                                                  .resultsCompare[2]
                                                                  .rate
                                                              )
                                                          ) +
                                                          "%"
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item-subtitle",
                                            {
                                              style: [
                                                {
                                                  display: "flex",
                                                  "justify-content": "center"
                                                }
                                              ]
                                            },
                                            [
                                              _vm._v(
                                                "\n                (" +
                                                  _vm._s(
                                                    _vm.resultsCompare[2].rate
                                                  ) +
                                                  "%)\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c("v-col", { attrs: { cols: "12" } }, [
                            _c(
                              "p",
                              { staticClass: "text-h6 text--primary mb-2" },
                              [_vm._v("\n            No data\n          ")]
                            )
                          ])
                        ],
                        1
                      )
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "flex-row-reverse pt-1" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("viewDetails", _vm.name)
                        }
                      }
                    },
                    [_vm._v("\n        View\n      ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.resultsLoading
        ? _c("v-skeleton-loader", { attrs: { type: "image" } })
        : _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "text-h5 mb-2 pb-0" },
                [
                  _vm._v("\n      " + _vm._s(_vm.displayName) + "\n      "),
                  _c("v-spacer"),
                  _vm.infoDescription
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { top: "", "z-index": "15" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g({ attrs: { color: "info" } }, on),
                                      [
                                        _vm._v(
                                          "\n            mdi-information\n          "
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1838283674
                          )
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.infoDescription))])]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-card-text", [_vm._v("\n      No data\n    ")])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }